<template>
  <div class="mainform">
    <div class="form formlabel">
      <el-form  class="content bascform" ref="addpayplan" :model="form"  label-width="130px">
        <div class="col col4">
          <el-form-item label="次级指标表代码" prop="secondaryCode" >
            <el-input v-model="form.secondaryCode" placeholder=""></el-input>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="次级指标表描述" prop="secondaryName" >
            <el-input v-model="form.secondaryName" placeholder=""></el-input>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="理论上限值" prop="upper" >
            <el-input v-model="form.upper" placeholder=""></el-input>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="次级指标详细说明" prop="strExplain" >
            <el-input v-model="form.strExplain" placeholder=""></el-input>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="是否启用" prop="status" >
            <el-checkbox v-model="form.status"></el-checkbox>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'

export default {
  name: 'DefinitionAddDialog',
  components: { ...Component },
  data: function () {
    return {
      form: {}
    }
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tableList.scss';
@import '@/assets/css/elForm.scss';
.mainform{
  .form{
    padding: 0px 0px;
  }
  .formlabel{
    :deep(.el-form-item__label){
      font-size: 14px !important;
    }
  }
}
</style>
